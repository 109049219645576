import request from '@/utils/http'

// 消纳场工单列表
export function queryList(params) {
  return request({
    url: '/recycleOrder/pc/list',
    method: 'get',
    params
  })
}





