const baseColumn = vm => {
  return [
    {
      keyId: 1,
      title: '创建时间',
      field: 'created',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}
// 操作按钮配置项
const operateList = vm => {
  return []
}

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 11,
      title: '车牌号码',
      field: 'carPlate',
      width: 160,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      renderType: 'status',
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    },
    {
      "keyId": 2,
      "title": "消纳场名称",
      "field": "recycleProjectName",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 3,
      "title": "工地名称",
      "field": "projectName",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 4,
      "title": "工地工单",
      "field": "projectOrderNo",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
  ]
}

// 表格列配置
const tableFields = vm => {
  return [
    {
      "keyId": 1,
      "title": "工单编号",
      "field": "orderNo",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 2,
      "title": "车牌号码",
      "field": "carPlate",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 2,
      "title": "消纳场名称",
      "field": "recycleProjectName",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 3,
      "title": "工地名称",
      "field": "projectName",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 4,
      "title": "工地工单",
      "field": "projectOrderNo",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 5,
      "title": "入场时间",
      "field": "created",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
   /* {
      "keyId": 6,
      "title": "设备序列号",
      "field": "devSerial",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 7,
      "title": "设备通道号",
      "field": "channel",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 8,
      "title": "设备通道类型",
      "field": "channelType",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },*/
    {
      "keyId": 9,
      "title": "车头照片",
      "field": "enterFrontImage",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": 'image',
      "copy": true
    },
    {
      "keyId": 9,
      "title": "车斗照片",
      "field": "enterBodyImage",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": 'image',
      "copy": true
    },
    {
      "keyId": 9,
      "title": "备注",
      "field": "remark",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    ]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}
