<template>
    <div style="height: 100%; width: 100%; ">
      <search-table
          ref="searchTable"
          labelWidth="70px"
          :loading="listLoading"
          :searchFields="searchFields"
          :autoBtnList="autoBtnList"
          :colSize="colSize"
          :total="total"
          :tableData="dataList"
          :tableColumns="tableColumns"
          :baseColumns="baseColumns"
          :sortFields="sortFields"
          :checkboxColumn="false"
          :hasPagination="true"
          :showFooter="true"
          :showSetting="false"
          :showColumnSearch="false"
          :showSortable="false"
          :showSortSetting="false"
          :tableSummary="tableSummary"
          :proxyParams="proxyParams"
          :optionColumn="optionColumn"
          :selectTableSummary="selectTableSummary"
          @saveTableField="saveTableField"
          @saveSearchField="saveSearchField"
          @saveSortField="saveSortField"
          @getTableData="queryList"
      >
      </search-table>

    </div>
  </template>
  <script>
  import { queryList } from '@/api/recycleOrder'
  import SearchTable from '@/components/LcSearchTable'
  import config from '@/const/recycle/recycleOrder.js'
  import { formatDate } from '@/utils/time'
  import { columnSetting } from '@/api/common'
  import column from '@/mixins/column.js'
  import store from "@/store";

  export default {
    components: {
      SearchTable,
    },
    mixins: [column],

    data: function () {
      return {
        total: 0,
        summary: {},
        dataList: [],
        listLoading: false,
        baseColumn: [], //接口请求的表格配置
        baseColumns: [],
        tableColumns: [], //用户自定义的表格配置
        searchFields: [], //用户自定义的search配置
        tableSummary: {}, //后端返回的总合计数据
        selectTableData: [], //checkbox选中的表格数据
        colSize: 3, //用户设置展示列比例
        selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
        sortFields: [], // 用户自定义的排序设置
        formParams: {}, // 当前查询的参数
        path: '', // 更新字段名显示接口路径
      }
    },
    created() {
      this.searchFields = config.searchFields(this)
      this.baseColumns = config.tableFields(this)
      this.tableColumns = config.tableFields(this)
      console.info("this.tableColumns", this.tableColumns)
    },

    computed: {
      // 操作按钮
      autoBtnList() {
        return config.operateList(this)
      }
    },
    activated() {
      this.queryList()
      console.info("this.tableColumns22", this.tableColumns)
    },
    methods: {
      // 查询
      async queryList(params) {
        if (params) {
          this.formParams = params
        } else {
          params = this.formParams
        }
        params['role'] = this.$store.getters.userType
        this.listLoading = true
        this.baseColumns = config.baseColumn(this)
        const res = await queryList({ ...params })

        if (res.code === 0) {
          this.dataList = res.data.items
          this.total = res.data.total * 1
        }
        this.searchFields.forEach(item => {
          if (item.field === 'name') {
            item.selectOptions = this.bossProList
          }
        })
        // console.info(this.dataList)
        // console.info(this.dataList.length)
        this.listLoading = false
      },
      initConfig() {
        this.tableColumns = this.baseColumns.filter(v => v.showField)
        this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
        this.sortFields = this.tableColumns
          .filter(v => v.sortable)
          .reduce((prev, item) => {
            // if(item.sortList) prev = prev.concat(item.sortList)
            prev.push({
              title: `${item.title}升序`,
              field: `${item.field}-asc`
            })
            prev.push({
              title: `${item.title}降序`,
              field: `${item.field}-desc`
            })
            return prev
          }, [])
      },
      // 时间格式化
      dateFormat(data) {
        return formatDate(data)
      },
      //保存|修改表格头配置
      async saveTableField(fields) {
        this.tableColumns = fields
        try {
          const res = await columnSetting({
            path: this.path,
            // searchFields: this.searchFields,
            tableFields: fields
          })
          this.getTableData()
        } catch (error) {
          console.log(error)
        }
      },
      //保存|修改搜索字段配置
      async saveSearchField(fields, colSize) {
        // console.log(fields, colSize)
        this.colSize = colSize
        this.searchFields = fields
        try {
          const res = await columnSetting({
            path: this.path,
            searchFields: fields,
            colSize
          })
        } catch (error) {
          console.log(error)
        }
      },
      //保存|修改排序字段配置
      async saveSortField(fields) {
        this.sortFields = fields
      },
      // 处理数据
      proxyParams(params) {
        console.log(params)
      },

      //获取表格头配置
      async getTableField() {},
      //获取搜索字段配置
      async getSearchField() {},
    }
  }
  </script>

  <style scoped>
  /deep/ .video-container-wrap  {
    position: fixed !important;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  </style>
